import type {FetchAction} from "@atg-shared/fetch-types";
import {call, FETCH} from "@atg-shared/fetch-redux";
import type {
    AnswerState,
    KycQuestionnaire,
    KycQuestionnaireAnswersRequest,
    UsersLastTimeModalViewed,
    AnswerQuestionnaire,
    ModalRequiredResponse,
    KycQuestionnaireState,
} from "@atg-aml-shared/kyc-types";
import {
    fetchKycMostRecentAnswer,
    fetchKycQuestionnaire,
    seyKycQuestionnaireAnswers,
    fetchKycCanSeeQuestionnaire,
    fetchKycModalRequired,
} from "../api/kycQuestionnaireApi";
import * as KycQuestionnaireActionsTypes from "./kycQuestionnaireActionsTypes";

type FetchCanSeeQuestionnaireAction = FetchAction<
    typeof KycQuestionnaireActionsTypes.FETCH_CAN_SEE_QUESTIONNAIRE_REQUEST,
    typeof KycQuestionnaireActionsTypes.FETCH_CAN_SEE_QUESTIONNAIRE_RECEIVE,
    boolean,
    KycQuestionnaireState
>;

type FetchModalRequiredAction = FetchAction<
    typeof KycQuestionnaireActionsTypes.FETCH_MODAL_REQUIRED_REQUEST,
    typeof KycQuestionnaireActionsTypes.FETCH_MODAL_REQUIRED_RECEIVE,
    ModalRequiredResponse,
    KycQuestionnaireState
>;

type FetchKycQuestionnaireQuestionAction = FetchAction<
    typeof KycQuestionnaireActionsTypes.FETCH_KYC_QUESTIONNAIRE_QUESTION_REQUEST,
    typeof KycQuestionnaireActionsTypes.FETCH_KYC_QUESTIONNAIRE_QUESTION_RECEIVE,
    KycQuestionnaireResponse,
    KycQuestionnaire
>;

export type FetchQuestionnaireMostRecentAction = FetchAction<
    typeof KycQuestionnaireActionsTypes.FETCH_KYC_ACTUAL_ANSWERS_REQUEST,
    typeof KycQuestionnaireActionsTypes.FETCH_KYC_ACTUAL_ANSWERS_RECEIVE,
    AnswerQuestionnaire
>;

/**
 * Fetch customer last KYC answer
 */
export const fetchQuestionnaireMostRecent = (): FetchQuestionnaireMostRecentAction => ({
    type: FETCH,
    payload: {
        requestAction: KycQuestionnaireActionsTypes.FETCH_KYC_ACTUAL_ANSWERS_REQUEST,
        receiveAction: KycQuestionnaireActionsTypes.FETCH_KYC_ACTUAL_ANSWERS_RECEIVE,
        callApi: call(fetchKycMostRecentAnswer),
    },
});

export const resetKycState = (): ResetKycStateAction => ({
    type: KycQuestionnaireActionsTypes.RESET_KYC_STATE,
});

type StartKycQuestionnaireFlowAction = {
    type: typeof KycQuestionnaireActionsTypes.START_KYC_QUESTIONNAIRE_FLOW;
};

type CanSeeKycQuestionnaireInAccountAction = {
    type: typeof KycQuestionnaireActionsTypes.CAN_SEE_KYC_QUESTIONNAIRE_IN_ACCOUNT;
};

type FinishKycQuestionnaireFlowAction = {
    type: typeof KycQuestionnaireActionsTypes.FINISH_KYC_QUESTIONNAIRE_FLOW;
};

type CloseKycQuestionnaireFlowAction = {
    type: typeof KycQuestionnaireActionsTypes.CLOSE_KYC_QUESTIONNAIRE_FLOW;
};

type CanSeeKycQuestionnaireBeforeBetAction = {
    type: typeof KycQuestionnaireActionsTypes.CAN_SEE_KYC_QUESTIONNAIRE_BEFORE_BET;
};

export type UpdatedTimeWhenCustomerSawKycAfterLoginAction = {
    type: typeof KycQuestionnaireActionsTypes.UPDATED_TIME_WHEN_CUSTOMER_SAW_KYC_AFTER_LOGIN;
    payload: UsersLastTimeModalViewed;
};

type KycQuestionnaireResponse = {
    questionnaire: KycQuestionnaire;
};

type ResetKycQuestionnaireAction = {
    type: typeof KycQuestionnaireActionsTypes.KYC_QUESTIONNAIRE_ANSWERS_RESET;
};

export type UpdateKycQuestionnaireQuestionAction = {
    type: typeof KycQuestionnaireActionsTypes.UPDATE_KYC_QUESTIONNAIRE_QUESTION;
    payload: AnswerQuestionnaire;
};

export type ResetKycStateAction = {
    type: typeof KycQuestionnaireActionsTypes.RESET_KYC_STATE;
};

export type SetKycQuestionnaireQuestionsAction = FetchAction<
    typeof KycQuestionnaireActionsTypes.KYC_QUESTIONNAIRE_ANSWERS_REQUEST,
    typeof KycQuestionnaireActionsTypes.KYC_QUESTIONNAIRE_ANSWERS_RECEIVE,
    AnswerState,
    KycQuestionnaireAnswersRequest
>;

/**
 * called if customer is enable to see KYC form, in "Mina pengar" page.
 */
export const canSeeKycQuestionnaireInAccount =
    (): CanSeeKycQuestionnaireInAccountAction => ({
        type: KycQuestionnaireActionsTypes.CAN_SEE_KYC_QUESTIONNAIRE_IN_ACCOUNT,
    });

/**
 * called when we start KYC flow
 */
export const startKycQuestionnaireModalFlow = (): StartKycQuestionnaireFlowAction => ({
    type: KycQuestionnaireActionsTypes.START_KYC_QUESTIONNAIRE_FLOW,
});

/**
 * called when customer filled KYC form in modal, and closed modal with success message.
 */
export const finishKycQuestionnaireFlow = (): FinishKycQuestionnaireFlowAction => ({
    type: KycQuestionnaireActionsTypes.FINISH_KYC_QUESTIONNAIRE_FLOW,
});

/**
 * called when customer closed KYC form in modal.
 */
export const closeKycQuestionnaireFlow = (): CloseKycQuestionnaireFlowAction => ({
    type: KycQuestionnaireActionsTypes.CLOSE_KYC_QUESTIONNAIRE_FLOW,
});

/**
 * called if customer is enable to see KYC form, in case if customer try make bet.
 */
export const canSeeKycQuestionnaireBeforeBet =
    (): CanSeeKycQuestionnaireBeforeBetAction => ({
        type: KycQuestionnaireActionsTypes.CAN_SEE_KYC_QUESTIONNAIRE_BEFORE_BET,
    });

/**
 * Called when needed updated time, when customer saw modal after login
 * @param payload - actual DisplayAreas where's KYC will showing.
 */
export const updatedTimeWhenCustomerSawKycAfterLogin = (
    payload: UsersLastTimeModalViewed,
): UpdatedTimeWhenCustomerSawKycAfterLoginAction => ({
    type: KycQuestionnaireActionsTypes.UPDATED_TIME_WHEN_CUSTOMER_SAW_KYC_AFTER_LOGIN,
    payload,
});

/**
 * sending last customer answer to server.
 * @param kycQuestionnaireAnswers - KycQuestionnaireAnswersRequest type with actual customer answer.
 */
export const setKycQuestionnaireAnswers = (
    kycQuestionnaireAnswers: KycQuestionnaireAnswersRequest,
): SetKycQuestionnaireQuestionsAction => ({
    type: FETCH,
    payload: {
        requestAction: KycQuestionnaireActionsTypes.KYC_QUESTIONNAIRE_ANSWERS_REQUEST,
        receiveAction: KycQuestionnaireActionsTypes.KYC_QUESTIONNAIRE_ANSWERS_RECEIVE,
        callApi: call(seyKycQuestionnaireAnswers, kycQuestionnaireAnswers),
    },
});

export const fetchCanSeeQuestionnaire = (): FetchCanSeeQuestionnaireAction => ({
    type: FETCH,
    payload: {
        requestAction: KycQuestionnaireActionsTypes.FETCH_CAN_SEE_QUESTIONNAIRE_REQUEST,
        receiveAction: KycQuestionnaireActionsTypes.FETCH_CAN_SEE_QUESTIONNAIRE_RECEIVE,
        callApi: call(fetchKycCanSeeQuestionnaire),
    },
});

export const fetchModalRequired = (): FetchModalRequiredAction => ({
    type: FETCH,
    payload: {
        requestAction: KycQuestionnaireActionsTypes.FETCH_MODAL_REQUIRED_REQUEST,
        receiveAction: KycQuestionnaireActionsTypes.FETCH_MODAL_REQUIRED_RECEIVE,
        callApi: call(fetchKycModalRequired),
    },
});

/**
 * Reset state of KYC fetch request
 */
export const resetKycQuestionnaire = (): ResetKycQuestionnaireAction => ({
    type: KycQuestionnaireActionsTypes.KYC_QUESTIONNAIRE_ANSWERS_RESET,
});

/**
 * Update last customer answer in case when customer send update request on server and update was successfully.
 * Used to prevent unnecessary fetch request to actualize customer last answer
 * @param payload - AnswerQuestionnaire type with actual customer answer.
 */
export const updateKycQuestionnaireAnswers = (
    payload: AnswerQuestionnaire,
): UpdateKycQuestionnaireQuestionAction => ({
    type: KycQuestionnaireActionsTypes.UPDATE_KYC_QUESTIONNAIRE_QUESTION,
    payload,
});

/**
 * Fetch actual KYC questionnaire questions
 */
export const fetchKycQuestionnaireQuestions =
    (): FetchKycQuestionnaireQuestionAction => ({
        type: FETCH,
        payload: {
            requestAction:
                KycQuestionnaireActionsTypes.FETCH_KYC_QUESTIONNAIRE_QUESTION_REQUEST,
            receiveAction:
                KycQuestionnaireActionsTypes.FETCH_KYC_QUESTIONNAIRE_QUESTION_RECEIVE,
            callApi: call(fetchKycQuestionnaire),
        },
    });

export type Action =
    | UpdatedTimeWhenCustomerSawKycAfterLoginAction
    | FetchKycQuestionnaireQuestionAction
    | FetchQuestionnaireMostRecentAction
    | ResetKycQuestionnaireAction
    | UpdateKycQuestionnaireQuestionAction
    | FinishKycQuestionnaireFlowAction
    | CloseKycQuestionnaireFlowAction
    | ResetKycStateAction
    | FetchCanSeeQuestionnaireAction
    | FetchModalRequiredAction;
