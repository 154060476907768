import {flow, map} from "lodash/fp";
import dayjs from "dayjs";
import {createStore, applyMiddleware, compose} from "redux";
import type {Middleware} from "redux";
import createSagaMiddleware from "redux-saga";
import thunkMiddleware from "redux-thunk";
import {persistStore} from "redux-persist";
import type {ActionEntry} from "redux-action-log";
import {createActionLog} from "redux-action-log";
import type {CreateReducerFunc} from "@atg-shared/lazy-store";
import {initLazyStore} from "@atg-shared/lazy-store";
import log, {serializeError} from "@atg-shared/log";
import Features, {reduxDevTools, activateReduxTrace} from "@atg-shared/client-features";
import {RouterActions} from "@atg-shared/router";
// @ts-expect-error Flow
import {matchMediaDriver} from "atg-match-media";
import {fetchMiddleware} from "@atg-shared/fetch-redux";
import loggerMiddleware from "./redux-logger";

type ConfigureStoreOptions = {
    initialState?: Record<string, unknown>;

    createReducer: CreateReducerFunc<any, any>;

    /**
     * The middleware to setup for this store
     */
    middlewares?: Array<Middleware>;

    name?: string;
};

/**
 * Create a Redux store with lazy-load functionality
 *
 * A store created with this helper function will be able to lazily inject new reducers and sagas
 * by using the helper functions in `atg-lazy-store`.
 */
export default function configureStore({
    initialState,
    createReducer,
    middlewares = [],
    name,
}: ConfigureStoreOptions) {
    /**
     * Keeps track of the most recently dispatched actions. Can be forwarded to Splunk for
     * troubleshooting hard-to-reproduce issues.
     *
     * At the time of writing there's a 100 KB limit on the `/services/v1/logs` endpoint, so you might
     * need to filter the actionLog before logging it.
     */
    const actionLog = createActionLog({limit: 50});

    /**
     * Get a list of the most recent Redux actions (truncated: type + timestamp + error, but no payload)
     *
     * Note: adding the full `payload` is not realistic if sending this to our logging endpoint
     * (`/services/v1/logs`), since that would easily push us over its 100 KB limit.
     */
    const filteredActionLog = (): Array<
        ActionEntry & {
            timestamp: string;
        }
    > =>
        flow([
            map((item: ActionEntry) =>
                // URL changes often give us important clues for troubleshooting, so include the full payload for those actions
                item.action.type === RouterActions.ROUTER_ON_LOCATION_CHANGED
                    ? item.action
                    : {
                          type: item.action.type,
                          error: item.action.error,
                      },
            ),
            // include a client timestamp so that we can measure the time between actions
            map((item: ActionEntry) => ({
                ...item,
                timestamp: dayjs(item.timestamp).toISOString(),
            })),
        ])(actionLog.getLog().actions);

    const sagaMiddleware = createSagaMiddleware({
        onError: (error, details) =>
            log.error("uncaught redux-saga error", {
                error: serializeError(error),
                details,
                storeName: name,
            }),
    });

    const middleware = applyMiddleware(
        thunkMiddleware, // kill this eventually (replace with redux-saga)
        sagaMiddleware,
        loggerMiddleware,
        // include supplied microFE middlewares before fetchMiddleware
        // This enables FETCH actions to pass through the microFE middlewares before being "captured" by fetchMiddleware
        ...middlewares,
        fetchMiddleware,
    );

    const enableReduxDevtools =
        (process.env.NODE_ENV === "development" || Features.isEnabled(reduxDevTools)) &&
        // @ts-expect-error
        // eslint-disable-next-line no-underscore-dangle
        window.__REDUX_DEVTOOLS_EXTENSION__;

    const rootReducer = createReducer();

    const store = createStore(
        rootReducer,
        initialState,
        compose(
            middleware,
            actionLog.enhancer,
            enableReduxDevtools
                ? // @ts-expect-error
                  // eslint-disable-next-line no-underscore-dangle
                  window.__REDUX_DEVTOOLS_EXTENSION__({
                      name,
                      trace: Features.isEnabled(activateReduxTrace),
                      // increasing the trace limit to 20 instead of default 10
                      // helps with all the things redux-saga is doing internally
                      traceLimit: Features.isEnabled(activateReduxTrace) && 20,
                  })
                : (f: any) => f,
        ),
    );

    const persistor = persistStore(store);

    // TODO: remove eventually, `atg-match-media` is superseeded by `atg-breakpoints`
    matchMediaDriver(store.dispatch);

    const lazyStore = initLazyStore(store, createReducer, sagaMiddleware);

    lazyStore.persistor = persistor;

    // since we run a multi-store setup for our microFE, having the store name on the store object
    // is useful to know which store one is looking at when troubleshooting
    lazyStore.name = name;

    return {store: lazyStore, filteredActionLog, persistor};
}
