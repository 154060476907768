import root from "window-or-global";

export const getSystemName = () => root.clientConfig?.systemName ?? "atgse";

export const isTillsammans = () => getSystemName() === "tillsammans";

/**
 * This is only for the native part of the app. The app got several different webviews, e.g. sport,
 * and then other functions needs to be used.
 */
export const isApp = getSystemName() === "ATGApp";

/**
 * This is only for the sport webviews (sportsbook and odds receipts, NOT big 9) part of the app.
 */
export const isSportApp = getSystemName() === "ATG-Sports-App";

/**
 * This is only for the casino webview (the actual running game, starburst etc). The other part of the casino is native.
 */
export const isCasinoApp = getSystemName() === "ATG-Casino-App";

/**
 * This is only for the app webviews we have on "Mitt konto" etc but not for sportsbook nor casino webviews.
 */
export const isAppWebview = root.location?.pathname?.startsWith("/app/v");

export const isNotAppAndTillsammans = getSystemName() === "atgse" && !isAppWebview;

export const isWeb = !isApp;
